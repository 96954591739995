import React from 'react';
import styled from 'styled-components';
import emailIcon from '../assets/email.svg';
import instagramIcon from '../assets/instagram.svg';

const username = 'lisamortsmakeup';
const email = 'lisamortsmakeup@gmail.com';

const Footer = () => (
  <Container>
    <div>
      <a
        class="with-icon"
        href={`https://www.instagram.com/${username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={instagramIcon} alt="Instagram" />
        <p className="caption">{username}</p>
      </a>
      <a class="with-icon" href={`mailto:${email}`}>
        <img src={emailIcon} alt="Email" />
        <p className="caption">{email}</p>
      </a>
    </div>
    <div>
      <p class="caption">
        Website by{' '}
        <a
          href="https://homestud.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Home Studio
        </a>
      </p>
      <p class="caption">© Lisa Mortimer {new Date().getFullYear()}</p>
    </div>
  </Container>
);

export default Footer;

const Container = styled.footer`
  background: #f2e6e4;
  width: 100%;
  padding: 2rem;
  padding-bottom: calc(2rem + env(safe-area-inset-bottom));
  font-size: 0.8125rem;
  margin: 10rem 0 0;

  @media (min-width: 700px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  div {
    margin: 0 0 1.5rem 0;

    &:last-child {
      margin: 0;
    }

    @media (min-width: 700px) {
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  a {
    border: none;
    cursor: ne-resize;

    &:hover {
      opacity: 0.5;
    }
  }

  a.with-icon {
    display: flex;
    margin: 0 0 0.5rem 0;

    img {
      display: block;
      margin-right: 0.5rem;
    }

    @media (min-width: 700px) {
      margin: 0 1.5rem 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  /* div > a {
    display: block;

    @media (min-width: 700px) {
      margin-right: 1.5rem;
    }
  } */

  p {
    display: block;

    @media (min-width: 700px) {
      margin: 0 1.5rem 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }
`;
