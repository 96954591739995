import React, { useContext, useState } from 'react';
// import { Link } from 'gatsby';
import TransitionLink from 'gatsby-plugin-transition-link';
import styled from 'styled-components';
import Logo from './Logo';
import hamburger from '../assets/hamburger.svg';
import close from '../assets/close.svg';
import emailIcon from '../assets/email.svg';
import instagramIcon from '../assets/instagram.svg';
import { OverlayContext } from '../contexts/overlayContext';

const username = 'lisamortsmakeup';
const email = 'lisamortsmakeup@gmail.com';

const Header = ({ hideLogo }) => {
  const overlayContext = useContext(OverlayContext);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Container>
      <LogoLink
        hide={hideLogo}
        to="/"
        exit={{
          length: 0.2,
        }}
        entry={{ delay: 0.2, length: 0 }}
      >
        <Logo />
      </LogoLink>

      <Hamburger onClick={() => setShowMenu(!showMenu)}>
        <img
          src={showMenu ? close : hamburger}
          alt={showMenu ? 'Close menu' : 'Open menu'}
        />
      </Hamburger>
      <MobileMenu
        style={{
          opacity: showMenu ? 1 : 0,
          pointerEvents: showMenu ? 'all' : 'none',
        }}
      >
        <nav>
          <TransitionLink to="/">Home</TransitionLink>
          <TransitionLink to="/about">About</TransitionLink>
          <TransitionLink to="/portfolio">Portfolio</TransitionLink>
          <TransitionLink to="/contact">Contact</TransitionLink>
        </nav>
        <footer>
          <a
            class="with-icon"
            href={`https://www.instagram.com/${username}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagramIcon} alt="Instagram" />
            <p className="caption">{username}</p>
          </a>
          <a class="with-icon" href={`mailto:${email}`}>
            <img src={emailIcon} alt="Email" />
            <p className="caption">{email}</p>
          </a>
          <p className="caption">
            Website by{' '}
            <a
              href="https://homestud.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Home Studio
            </a>
          </p>
          <p className="caption">© Lisa Mortimer {new Date().getFullYear()}</p>
        </footer>
      </MobileMenu>
      <Nav>
        <NavLink
          to="/about"
          exit={{
            length: 0.2,
          }}
          entry={{ delay: 0.2, length: 0 }}
        >
          About
        </NavLink>
        <NavLink
          to="/portfolio"
          exit={{
            trigger: () => overlayContext.setShowOverlay(true),
            length: 0.6,
          }}
          entry={{ delay: 0.6, length: 0 }}
        >
          Portfolio
        </NavLink>
        <NavLink
          to="/contact"
          exit={{
            length: 0.2,
          }}
          entry={{ delay: 0.2, length: 0 }}
        >
          Contact
        </NavLink>
      </Nav>
    </Container>
  );
};

export default Header;

const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem;
  z-index: 1000;

  @media (min-width: 700px) {
    padding: 1.5rem;
  }
`;

const Nav = styled.nav`
  display: none;
  margin-left: auto;

  @media (min-width: 700px) {
    display: block;
  }
`;

const Hamburger = styled.button`
  margin: 0 0 0 auto;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  z-index: 10;
  cursor: pointer;

  @media (min-width: 700px) {
    display: none;
  }

  img {
    display: block;
  }
`;

const MobileMenu = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  top: 0;
  left: 0;
  transition: opacity 300ms;
  padding: 5.5rem 2rem 0;
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  flex-direction: column;
  /* z-index: 1000; */

  @media (min-width: 700px) {
    display: none;
  }

  nav {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    a {
      font-family: display;
      font-size: 2rem;
      line-height: 1.25;
      display: block;
      margin: 0 0 1rem;
      border: none;

      &:last-child {
        margin: 0;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }

  footer {
    padding: 2rem 0;

    a {
      border: none;
    }

    a.with-icon {
      display: flex;
      margin: 0 0 0.5rem;

      &:last-of-type {
        margin: 0 0 1.5rem;
      }

      img {
        display: block;
        margin-right: 0.5rem;
      }
    }
  }
`;

const NavLink = ({ children, to, exit, entry }) => (
  <StyledLink to={to} entry={entry} exit={exit} activeClassName="active">
    {children}
  </StyledLink>
);

const LogoLink = styled(TransitionLink)`
  z-index: 10;
  border: none;

  @media (min-width: 700px) {
    display: ${({ hide }) => (hide ? 'none' : 'block')};
  }
`;

const StyledLink = styled(TransitionLink)`
  opacity: 0.5;
  font-size: 0.8125rem;
  color: inherit;
  border: none;
  display: inline-block;
  margin: 0 0 0 1rem;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
  }
`;
