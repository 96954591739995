import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { TransitionState } from 'gatsby-plugin-transition-link';
import GlobalStyles from '../styles/GlobalStyles';
import Header from './Header';
import Footer from './Footer';
import { PrevLocationContext } from '../contexts/prevLocationContext';
import { OverlayContext } from '../contexts/overlayContext';
import { BackgroundContext } from '../contexts/backgroundContext';

const Layout = ({ children, background, hideLogo, location }) => {
  const locationContext = useContext(PrevLocationContext);
  const overlayContext = useContext(OverlayContext);
  const backgroundContext = useContext(BackgroundContext);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (location && locationContext) {
      locationContext.setPrevLocation(location.pathname);
    }

    if (backgroundContext) {
      backgroundContext.setBackgroundColor(background || '#F8F3F3');
    }
  }, []);

  return (
    <TransitionState>
      {({ transitionStatus }) => (
        <main>
          <GlobalStyles />
          <Helmet>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            />
          </Helmet>

          <Overlay show={overlayContext?.showOverlay} />
          <Header hideLogo={hideLogo} location={location} />

          <div
            style={{
              opacity: ['POP', 'entering', 'entered'].includes(transitionStatus)
                ? 1
                : 0,
              transition: 'opacity 300ms',
            }}
          >
            {children}
          </div>
          <Footer />
        </main>
      )}
    </TransitionState>
  );
};

export default Layout;

const Overlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 2000;
  transform: ${({ show }) => (show ? null : 'translateY(100%)')};
  transition: transform 400ms 300ms;
  will-change: transform;
`;
